// Globals

// colors
$koderia-red: #d14743;
$koderia-red-accent: #ffebec;
$koderia-red-alternative: #ff6a66;

// $koderia-green: #3cb46f; << OLD
$koderia-green: #0ea66d;
$koderia-green-hover: #38a868;
$koderia-green-text: #28844f;
$koderia-dark-green: #0E9562;
$koderia-green-accent: #e6f4eb;
$koderia-green-alternative: #aad4c8;

$koderia-blue: #404ba0;
$koderia-blue-accent: #5463d0;
$koderia-blue-alternative: #ebedff;

$koderia-orange: #e58d00;
$koderia-orange-accent: #ffae2c;
$koderia-orange-alternative: #fff7dd;
$koderia-extra-orange: #ff896f;

$koderia-black: #141A1D;
$koderia-input-black: #282a381a;
$koderia-input-black-alternative: #282a382a;
$koderia-grey: #7e7f87;
$koderia-light-grey: #c9c9cd;
$koderia-whitesmoke: #eeeef1;
$koderia-black-accent: #f8f8fb;

$koderia-border-grey: #ededf1;
$koderia-button-black: #1d1f29;
$koderia-input-bg: #fafbfd;

// new colors
//blacks
$kod-black: #141A1D;
$kod-grey: #7e7f87;
$kod-whitesmoke: #c9c9cd;
$kod-light-grey: #eeeef1;
$kod-lighter-grey: #f8f8fb;
$kod-lightest-grey: #fafbfd;
//greens
$kod-green: #0f9c5d;
$kod-light-green: #3cb46f;
$kod-green-accent: #75cc9a;
$kod-light-green-accent: #e6f4e9;
// blues
$kod-blue: #404ba0;
$kod-light-blue: #5463d0;
$kod-blue-accent: #838dd9;
$kod-light-blue-accent: #ebedff;
// reds
$kod-red: #d14743;
$kod-light-red: #ff6a66;
$kod-red-accent: #ffa9a7;
$kod-light-red-accent: #ffebec;
// yellows:
$kod-yellow: #e58d00;
$kod-light-yellow: #ffae2c;
$kod-yellow-accent: #ffc76e;
$kod-light-yellow-accent: #fff7dd;

//utils
$kod-standard-bg: #f8f8fb;
$kod-fields-bg: #fafbfd;
$kod-hr: #ededf1;
$koderia-shadow: #1d1f291a;
$koderia-shadow-hover: #1d1f2933;

// new colors - same as tailwind.config.cjs
$koderia-white: #FFFFFF;
$koderia-black: #000000;
$koderia-gray-50: #f9fafb;
$koderia-gray-100: #f3f4f6;
$koderia-gray-200: #e5e7eb;
$koderia-gray-300: #d1d5db;
$koderia-gray-400: #9ca3af;
$koderia-gray-500: #6b7280;
$koderia-gray-600: #4b5563;
$koderia-gray-700: #374151;
$koderia-gray-800: #1f2937;
$koderia-gray-900: #111827;
$koderia: #0f9c5d;
$koderia-alt: #017f47;
$koderia-light: #e9fdef;
$koderia-green: #0d633c;
$koderia-green-dark: #052e1c;
$koderia-green-alt: #E3EFEA;
$koderia-green-alt-2: #DAF1E6;
$koderia-yellow: #6D5C50;
$koderia-yellow-alt: #F4E5D9;
$koderia-purple: #665BA7;
$koderia-purple-alt: #E5E3F3;
$koderia-red: #EC6868;
$koderia-red-alt: #ff906d;

// text settings
$headings: (
  "h1": (
    "size": 3.5rem,
    "line-height": 4.625rem,
  ),
  "h2": (
    "size": 2.75rem,
    "line-height": 3.625rem,
  ),
  "h3": (
    "size": 2rem,
    "line-height": 2.625rem,
  ),
  "h4": (
    "size": 1.5rem,
    "line-height": 2rem,
  ),
  "h5": (
    "size": 1.25rem,
    "line-height": 1.625rem,
  ),
  "h6": (
    "size": 1rem,
    "line-height": 1.5rem,
  ),
);
$text: (
  "leader": (
    "size": 1.25rem,
    "line-height": 2rem,
  ),
  "body": (
    "size": 1rem,
    "line-height": 1.5rem,
  ),
  "small": (
    "size": 0.875rem,
    "line-height": 1.25rem,
  ),
  "extra-small": (
    "size": 0.75rem,
    "line-height": 1.125rem,
  ),
  "post-extra-small": (
    "size": 0.688rem,
    "line-height": 1rem,
  ),
  "title": (
    "size": 1rem,
    "line-height": 1.5rem,
    "font-weight": bold,
  ),
  "input": (
    "size": 1rem,
    "line-height": 1.5rem,
  ),
  "button": (
    "size": 1rem,
    "line-height": 1.5rem,
    "font-weight": bold,
  ),
  "bar-link": (
    "size": 0.938rem,
    "line-height": 1.438rem,
  ),
  "heading": (
    "size": 1.5rem,
    "line-height": 2rem,
  ),
  "medium-heading": (
    "size": 2rem,
    "line-height": 2.625rem,
  ),
  "big-heading": (
    "size": 2.75rem,
    "line-height": 3.625rem,
  ),
);

// margin mixin
$margin: 0.5rem;
@mixin margin-multiplier($multiplier) {
  margin: $multiplier * $margin;
}

@mixin margin-top-multiplier($multiplier) {
  margin-top: $multiplier * $margin !important;
}

@mixin margin-bottom-multiplier($multiplier) {
  margin-bottom: $multiplier * $margin !important;
}

@mixin margin-left-multiplier($multiplier) {
  margin-left: $multiplier * $margin !important;
}

@mixin margin-right-multiplier($multiplier) {
  margin-right: $multiplier * $margin !important;
}

// padding mixin
$padding: 0.5rem;
@mixin padding-multiplier($multiplier) {
  padding: $multiplier * $padding !important;
}

@mixin padding-top-multiplier($multiplier) {
  padding-top: $multiplier * $padding !important;
}

@mixin padding-bottom-multiplier($multiplier) {
  padding-bottom: $multiplier * $padding !important;
}

@mixin padding-left-multiplier($multiplier) {
  padding-left: $multiplier * $padding !important;
}

@mixin padding-right-multiplier($multiplier) {
  padding-right: $multiplier * $padding !important;
}

:export {
  kodBlack: $kod-black;
  kodWhitesmoke: $koderia-whitesmoke;
  kodGreen: $kod-green;
  kodStandardBG: $kod-standard-bg;
}

$smallHeadings: (
  "h1": (
    "size": 2.75rem,
    "line-height": 3.625rem,
  ),
  "h2": (
    "size": 2rem,
    "line-height": 2.625rem,
  ),
  "h3": (
    "size": 1.5rem,
    "line-height": 2rem,
  ),
  "h4": (
    "size": 1.125rem,
    "line-height": 1.5rem,
  ),
  "h5": (
    "size": 1.125rem,
    "line-height": 1.5rem,
  ),
);

$smallText: (
  "leader": (
    "size": 1rem,
    "line-height": 1.5rem,
  ),
  "body": (
    "size": 1rem,
    "line-height": 1.5rem,
  ),
  "small": (
    "size": 0.875rem,
    "line-height": 1.25rem,
  ),
  "extra-small": (
    "size": 0.75rem,
    "line-height": 1.125rem,
  ),
  "post-extra-small": (
    "size": 0.688rem,
    "line-height": 1rem,
  ),
  "title": (
    "size": 1rem,
    "line-height": 1.5rem,
    "font-weight": bold,
  ),
  "input": (
    "size": 1rem,
    "line-height": 1.5rem,
  ),
  "button": (
    "size": 1rem,
    "line-height": 1.5rem,
    "font-weight": bold,
  ),
  "bar-link": (
    "size": 0.938rem,
    "line-height": 1.438rem,
  ),
  "heading": (
    "size": 1.5rem,
    "line-height": 2rem,
  ),
  "medium-heading": (
    "size": 2rem,
    "line-height": 2.625rem,
  ),
  "big-heading": (
    "size": 2.75rem,
    "line-height": 3.625rem,
  ),
);

$screen-sm-min: 40rem;
$screen-md-min: 48rem;
$screen-lg-min: 64rem;
$screen-xl-min: 80rem;

$screen-sm-max: 39.938rem;
$screen-md-max: 47.938rem;
$screen-lg-max: 63.938rem;
$screen-xl-max: 79.938rem;

// Small devices
@mixin sm {
  @media only screen and (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media only screen and (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media only screen and (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media only screen and (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Custom devices
@mixin screen($screen) {
  @media only screen and (min-width: $screen+"px") {
    @content;
  }
}

// Small devices inverted
@mixin -sm {
  @media only screen and (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// Medium devices inverted
@mixin -md {
  @media only screen and (max-width: #{$screen-md-max}) {
    @content;
  }
}

// Large devices inverted
@mixin -lg {
  @media only screen and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

// Extra large devices inverted
@mixin -xl {
  @media only screen and (max-width: #{$screen-xl-max}) {
    @content;
  }
}

// Custom devices inverted
@mixin -screen($screen) {
  @media only screen and (max-width: $screen+"px") {
    @content;
  }
}
